const defaultCalculationData = () => {
    const myDefaultCalculationData = {
        index: 0,
        inputValue: "",
        inputValueError: false,
        inputValueErrorMessage: "",
        inputUnit: "Celsius",
        outputUnit: "Fahrenheit",
        outputUnitError: false,
        outputUnitErrorMessage: "",
        studentResponse: "",
        studentResponseError: false,
        studentResponseErrorMessage: "",
        result: "entries incomplete"
    };
    return myDefaultCalculationData;
};

export default defaultCalculationData;