import React, { useContext } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataAndMethodsContext from "../context/dataAndMethods/dataAndMethodsContext";
import defaultCalculationData from "../model/defaultCalculationData";


const useStyles = makeStyles(() => ({
    grow: {
        flexGrow: 1,
    },
}));

const NavBar = () => {
    const classes = useStyles();
    const dataAndMethodsContext = useContext(DataAndMethodsContext);
    const {
        calculations,
        setCalculations,
    } = dataAndMethodsContext;

    const addCalculationClick = () => {
        let myNewCalculations = JSON.parse(JSON.stringify(calculations));
        const myDefaultCalculationData = defaultCalculationData();
        myDefaultCalculationData.index = myNewCalculations.length;
        myNewCalculations.push(myDefaultCalculationData);
        setCalculations(myNewCalculations);
    };

    const deleteLastCalculationClick = () => {
        let myNewCalculations = JSON.parse(JSON.stringify(calculations));
        myNewCalculations.pop();
        setCalculations(myNewCalculations);
    };

    return (
        <div className={classes.grow}>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <Tooltip title="Refresh">
                        <IconButton aria-label="Unit Conversion Refresh" color="inherit"
                            href="/"
                        >
                            Unit Conversion
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add item">
                        <IconButton data-testid="add-item" aria-label="Add item" color="inherit"
                            onClick={() => addCalculationClick()}
                        >
                            <i className="icon-list-solid-plus"></i>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete last">
                        <IconButton data-testid="delete-last" aria-label="Delete last" color="inherit"
                            onClick={() => deleteLastCalculationClick()}
                        >
                            <i className="icon-list-solid-minus"></i>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;