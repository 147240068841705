import validateInputs from "./validateInputs";
import calculateResult from "./calculateResult";

const validateInputsCalculateResult = (myCalculation) => {
    let calculationResult = validateInputs(myCalculation);
    if (calculationResult.result !== "entries incomplete" &&
        calculationResult.result !== "invalid" &&
        calculationResult.result !== "incorrect") {
        calculationResult = calculateResult(calculationResult);
    }
    return calculationResult;
};

export default validateInputsCalculateResult;



















