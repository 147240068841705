import React from "react";
import "./App.css";
import Home from "./pages/Home";
import DataAndMethodsState from "./context/dataAndMethods/dataAndMethodsState";

const App = () => {
  return (
    <DataAndMethodsState>
      <div className="App">
        <Home />
      </div>
    </DataAndMethodsState>
  );
};

export default App;

