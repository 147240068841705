/* eslint-disable react/prop-types */ // TODO: address this linting error when moving to typescript, see read me for future work
import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import conversionUnits from "../../model/conversionUnits";
import DataAndMethodsContext from "../../context/dataAndMethods/dataAndMethodsContext";

const CalculationCard = ({ calculation }) => {
    const dataAndMethodsContext = useContext(DataAndMethodsContext);
    const {
        setCalculationItem,
    } = dataAndMethodsContext;

    const {
        index,
        inputValue,
        inputUnit,
        inputValueError,
        inputValueErrorMessage,
        outputUnitError,
        outputUnitErrorMessage,
        outputUnit,
        studentResponse,
        studentResponseError,
        studentResponseErrorMessage,
        result,
    } = calculation;

    const changeInputValue = (e) => {
        setCalculationItem(index, "inputValue", e.target.value);
    };

    const changeInputUnit = (e) => {
        setCalculationItem(index, "inputUnit", e.target.value);
    };

    const changeOutputUnit = (e) => {
        setCalculationItem(index, "outputUnit", e.target.value);
    };

    const changeStudentResponse = (e) => {
        setCalculationItem(index, "studentResponse", e.target.value);
    };

    const convertFrom = conversionUnits();
    const convertTo = conversionUnits();

    const myStyle = {
        marginRight: "1rem",
        marginBottom: "1rem"
    };

    return (
        <>
            <div className='card' data-testid={"calculation-card"}>
                <TextField style={myStyle}
                    data-testid={"student-input"}
                    id='input-value'
                    label='Input value'
                    type='text'
                    variant='filled'
                    size='small'
                    value={inputValue}
                    error={inputValueError}
                    helperText={inputValueErrorMessage}
                    onChange={changeInputValue}
                />
                <TextField style={myStyle}
                    id='input-unit'
                    select
                    label='Input units'
                    value={inputUnit}
                    onChange={changeInputUnit}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {convertFrom.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField style={myStyle}
                    id='target-units'
                    select
                    label='Target unit'
                    value={outputUnit}
                    onChange={changeOutputUnit}
                    SelectProps={{
                        native: true,
                    }}
                    error={outputUnitError}
                    helperText={outputUnitErrorMessage}
                >
                    {convertTo.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField style={myStyle}
                    id='student-response'
                    label='Student response'
                    type='text'
                    variant='filled'
                    size='small'
                    value={studentResponse}
                    error={studentResponseError}
                    helperText={studentResponseErrorMessage}
                    onChange={changeStudentResponse}
                />
                <TextField style={myStyle}
                    inputProps={{
                        readOnly: Boolean(true),
                        disabled: Boolean(true),
                    }}
                    id='result'
                    label='Result'
                    type='text'
                    size='small'
                    value={result}
                />
            </div>
        </>
    );
};

export default CalculationCard;