const calculateResult = (myCalculation) => {
    let myIntermediateCalculation = 0;
    let myFinalCalculation = 0;

    // calculate an intermediate value, Kelvin is used for temperature
    // liters is used for volume
    switch (myCalculation.inputUnit) {
        case "Kelvin":
            // already in kevin just set intermediate value
            myIntermediateCalculation = parseFloat(myCalculation.inputValue);
            break;
        case "Celsius":
            //0°C + 273.15 = 273.15K
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) + 273.15;
            break;
        case "Fahrenheit":
            //(32°F − 32) × 5/9 + 273.15 = 273.15K
            myIntermediateCalculation = (parseFloat(myCalculation.inputValue) - 32) * 5 / 9 + 273.15;
            break;
        case "Rankine":
            //1°R × 5/9 = 0.5556K
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 5 / 9;
            break;
        case "liters":
            // already in liters just set intermediate value
            myIntermediateCalculation = parseFloat(myCalculation.inputValue);
            break;
        case "tablespoons":
            // 1tbsp * 0.0147868 = 0.0147868 liter
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 0.0147868;
            break;
        case "cubic-inches":
            // 1cuin * 0.0163871  = 0.0163871 liter
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 0.0163871;
            break;
        case "cups":
            // 1cup * 0.236588 = 0.236588 liter
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 0.236588;
            break;
        case "cubic-feet":
            // 1cuft * 28.3168 = 28.3168 liter
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 28.3168;
            break;
        case "gallons":
            // 1 gallon / 0.264172 =  3.785 liter
            myIntermediateCalculation = parseFloat(myCalculation.inputValue) * 3.785;
            break;
        default:
    }

    // now calculate the final value using the intermediate value
    switch (myCalculation.outputUnit) {
        case "Kelvin":
            // already in kevin just set to intermediate value
            myFinalCalculation = myIntermediateCalculation;
            break;
        case "Celsius":
            // 0K − 273.15 = -273.1°C
            myFinalCalculation = myIntermediateCalculation - 273.15;
            break;
        case "Fahrenheit":
            // (0K − 273.15) × 9/5 + 32 = -459.7°F
            myFinalCalculation = (myIntermediateCalculation - 273.15) * 9 / 5 + 32;
            break;
        case "Rankine":
            //1°K × 9/5 = 1.8R
            myFinalCalculation = myIntermediateCalculation * 9 / 5;
            break;
        case "liters":
            // already in liters just set to intermediate value
            myFinalCalculation = myIntermediateCalculation;
            break;
        case "tablespoons":
            // 1 liter / 0.0147868 =  67.628 tbsp
            myFinalCalculation = myIntermediateCalculation / 0.0147868;
            break;
        case "cubic-inches":
            // 1 liter / 0.0163871  = 61.0237 cuin
            myFinalCalculation = myIntermediateCalculation / 0.0163871;
            break;
        case "cups":
            // 1 liter / 0.236588 = 4.22675 cup
            myFinalCalculation = myIntermediateCalculation / 0.236588;
            break;
        case "cubic-feet":
            // 1 liter / 28.3168 =  0.0353147 cuft
            myFinalCalculation = myIntermediateCalculation / 28.3168;
            break;
        case "gallons":
            // 1 liter *0.264172 =  0.264172 gallon
            myFinalCalculation = myIntermediateCalculation * 0.264172;
            break;
        default:
    }

    if (parseFloat(myCalculation.studentResponse).toFixed(1) === myFinalCalculation.toFixed(1)) {
        myCalculation.result = "correct";
    } else {
        myCalculation.result = "incorrect";
    }

    return myCalculation;
};

export default calculateResult;