import {
    SET_LOADING,
    SET_CALCULATIONS,
} from "../types";

const dataAndMethodsReducer = (state, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_CALCULATIONS:
            return {
                ...state,
                calculations: action.payload,
            };
        default:
            return state;
    }
};

export default dataAndMethodsReducer;