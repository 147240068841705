import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Calculations from "../components/calculation/Calculations";

const Home = () => {
    useEffect(() => {
        async function fetchData() {
            // this is were I would hit the database or local storage to get saved values
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <NavBar />
            <div className='container'>
                <p className='home-page-top-margin'></p>
                <Calculations />
                <p className='home-page-bottom-margin'></p>
            </div>
        </>
    );
};

export default Home;
