import React, { useContext } from "react";
import CalculationCard from "./CalculationCard";
import DataAndMethodsContext from "../../context/dataAndMethods/dataAndMethodsContext";
import CircularIndeterminate from "../circularIndeterminate/CircularIndeterminate";

const Calculations = () => {
    const dataAndMethodsContext = useContext(DataAndMethodsContext);
    const {
        calculations,
        loading,
    } = dataAndMethodsContext;

    if (loading) {
        return <CircularIndeterminate />;
    } else {
        return calculations.map(calculation => <CalculationCard
            calculation={calculation}
            key={"my-calculation-" + calculation.index}
        />);
    }
};

export default Calculations;
