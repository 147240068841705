import conversionUnits from "./conversionUnits";
import isDecimal from "validator/lib/isDecimal";

// check if the input and output units are compatible if not 
// set result to invalid and post a error on output dropdown
const validateInputs = (myCalculation) => {
    const myConversionUnits = conversionUnits();
    myCalculation.inputValueError = false;
    myCalculation.inputValueErrorMessage = "";
    myCalculation.outputUnitError = false;
    myCalculation.outputUnitErrorMessage = "";
    myCalculation.studentResponseError = false;
    myCalculation.studentResponseErrorMessage = "";
    myCalculation.result = "";

    // get input and output unit types to check compatibility and use for compatibility error message
    let inputUnitType = "";
    for (let i = 0; i < myConversionUnits.length; i++) {
        if (myConversionUnits[i].value === myCalculation.inputUnit) {
            inputUnitType = myConversionUnits[i].type;
            break;
        }
    }
    let outputUnitType = "";
    for (let i = 0; i < myConversionUnits.length; i++) {
        if (myConversionUnits[i].value === myCalculation.outputUnit) {
            outputUnitType = myConversionUnits[i].type;
            break;
        }
    }

    if (inputUnitType !== outputUnitType) {
        myCalculation.outputUnitError = true;
        myCalculation.outputUnitErrorMessage = outputUnitType + " units are not compatible with " + inputUnitType + " units";
        myCalculation.result = "invalid";
    }

    // check if the input is a decimal number if not
    // set result to incorrect and post an error on input field
    if (!isDecimal(myCalculation.inputValue + "0") && myCalculation.inputValue !== "" && myCalculation.result !== "invalid") {
        myCalculation.inputValueError = true;
        myCalculation.inputValueErrorMessage = "must be a number";
        myCalculation.result = "incorrect";
    }

    // check if the student response is a decimal number if not
    // set result to incorrect and post an error on student response field
    if (!isDecimal(myCalculation.studentResponse + "0") && myCalculation.studentResponse !== "" && myCalculation.result !== "invalid") {
        myCalculation.studentResponseError = true;
        myCalculation.studentResponseErrorMessage = "must be a number";
        myCalculation.result = "incorrect";
    }

    // now check if either of the entries are blank and the result is not invalid or incorrect
    // if so set result to entries incomplete
    if (myCalculation.inputValue === "" || myCalculation.studentResponse === "") {
        if (myCalculation.result !== "invalid" && myCalculation.result !== "incorrect") {
            myCalculation.result = "entries incomplete";
            return myCalculation;
        }
    }

    return myCalculation;
};

export default validateInputs;
