const conversionUnits = () => {
    const myConversionUnits = [
        {
            value: "Kelvin",
            label: "Kelvin",
            type: "temperature"
        },
        {
            value: "Celsius",
            label: "Celsius",
            type: "temperature"
        },
        {
            value: "Fahrenheit",
            label: "Fahrenheit",
            type: "temperature"
        },
        {
            value: "Rankine",
            label: "Rankine",
            type: "temperature"
        },
        {
            value: "liters",
            label: "liters",
            type: "volume"
        },
        {
            value: "tablespoons",
            label: "tablespoons",
            type: "volume"
        },
        {
            value: "cubic-inches",
            label: "cubic-inches",
            type: "volume"
        },
        {
            value: "cups",
            label: "cups",
            type: "volume"
        },
        {
            value: "cubic-feet",
            label: "cubic-feet",
            type: "volume"
        },
        {
            value: "gallons",
            label: "gallons",
            type: "volume"
        },
        {
            value: "dog",
            label: "dog",
            type: "animal"
        },
        {
            value: "salmon",
            label: "salmon",
            type: "fish"
        },
        {
            value: "bee",
            label: "bee",
            type: "insect"
        }
    ];
    return myConversionUnits;
};

export default conversionUnits;