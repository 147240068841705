/* eslint-disable react/prop-types */ // TODO: address this linting error when moving to typescript, see read me for future work

import React, { useReducer } from "react";
import DataAndMethodsContext from "./dataAndMethodsContext";
import DataAndMethodsReducer from "./dataAndMethodsReducer";
import defaultCalculationData from "../../model/defaultCalculationData";
import validateInputsCalculateResult from "../../model/validateInputsCalculateResult";

import {
    SET_LOADING,
    SET_CALCULATIONS,
} from "../types";


const myDefaultCalculationData = [defaultCalculationData()];

const DataAndMethodsState = props => {
    const initialState = {
        loading: false,
        calculations: myDefaultCalculationData,
    };

    const [state, dispatch] = useReducer(DataAndMethodsReducer, initialState);

    // set loading spinner ---------------------------------------------------------------------
    const setLoading = (myBool) => dispatch({ type: SET_LOADING, payload: myBool });

    //set calculations -----------------------------------------------------
    const setCalculationItem = (index, key, value) => {
        let myNewCalculations = state.calculations;
        myNewCalculations[index][key] = value;
        myNewCalculations[index] = validateInputsCalculateResult(myNewCalculations[index]);
        setCalculations(myNewCalculations);
    };
    const setCalculations = (calculations) => { dispatch({ type: SET_CALCULATIONS, payload: calculations }); };

    return (
        <DataAndMethodsContext.Provider
            value={{
                loading: state.loading,
                calculations: state.calculations,
                setLoading,
                setCalculations,
                setCalculationItem,
            }}
        >
            {props.children}
        </DataAndMethodsContext.Provider>
    );
};

export default DataAndMethodsState;


